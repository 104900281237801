var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col md:w-1/1 w-full"},[_c('vx-card',{attrs:{"title":""}},[_c('div',{staticClass:"vx-row mb-12"},[_c('vs-button',{staticClass:"mr-10 mt-2",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-plus"},on:{"click":_vm.handleCreate}},[_vm._v(" Create ")])],1),_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"tbody"},_vm._l((_vm.data),function(tr,indextr){return _c('vs-tr',{key:indextr,class:[_vm.background(tr)]},[_c('vs-td',[_c('vs-button',{staticClass:"mr-10 mt-2",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-edit"},on:{"click":function($event){return _vm.handleEdit(tr.data.ID)}}})],1),_c('vs-td',[_vm._v(" "+_vm._s(tr.data.Code)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.approvalType.Reference)+" ")]),_c('vs-td',[_vm._l((tr.combination),function(combination){return [_c('div',{key:combination},[_vm._v(" "+_vm._s(combination.name_combination)+" : "+_vm._s(combination.line)+" "),_c('br')])]})],2),_c('vs-td',[_vm._v(" "+_vm._s(tr.data.Source)+" ")])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('div',[_vm._l((_vm.valueOption),function(r,i){return [_c('div',{key:i},[_vm._l((r),function(sr,si){return [_c('div',{key:i + si,staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",attrs:{"label":si,"name":"Name"},model:{value:(_vm.valueOption[i][si]),callback:function ($$v) {_vm.$set(_vm.valueOption[i], si, $$v)},expression:"valueOption[i][si]"}})],1)])]}),_c('hr')],2)]}),_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":_vm.handleSubmit}},[_vm._v("Submit")])],2)],1)]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }